import ApiService from "@/common/api/api.service";

const UsuarioService = {
  listar(porPagina = 0, pagina = 0) {
    return ApiService.get(`perfil?TamanhoDaPagina=${porPagina}&NumeroDaPagina=${pagina}`);
  },
  buscarPorId(id) {
    return ApiService.get(`perfil/${id}`);
  },
  salvar(form) {
    let result = [];
    if (!form.id) result = ApiService.post(`perfil`, { ...form });
    else result = ApiService.put(`perfil`, { ...form });
    return result;
  },
  excluir(id) {
    return ApiService.delete(`perfil?id=${id}`);
  },
};

export default UsuarioService;
